@import '../theme-bootstrap';

// This file styles defines the default styling for the product_brief partial.
// The default styling is exactly the way it appears on the MPP. If you need
// to customize this for some other usage, please don't do it here unless you
// intend to change the styles across the site.

// These placeholders float elements the proper amount left/right for mobile
// and reset for tablet. Can't just use containers because of the order.
@mixin pb-left {
  float: left;
  width: 42.336%;
  @include breakpoint($medium-up) {
    float: none;
    width: auto;
  }
}

@mixin pb-right {
  float: right;
  width: 57.664%;
  @include breakpoint($medium-down) {
    .product-extra-content & {
      width: 100%;
      padding: 0 35px 0 35px;
      text-align: center;
    }
  }
  @include breakpoint($medium-up) {
    float: none;
    width: auto;
  }
}

.product-brief {
  @include pie-clearfix;
  position: relative;
  padding: 30px 20px 20px 0;
  height: 100%;
  .product-extra-content__inner-wrapper {
    min-height: 300px;
  }
  &.product-extra-content {
    padding: 0;
    .product-brief__tagline {
      cursor: default;
      padding: 10px 0 15px 0;
      @include breakpoint($medium-up) {
        padding: 18px 0 0 0;
      }
    }
  }
  @include breakpoint($medium-up) {
    padding: 67px 20px 34px;
    text-align: center;
    &.product-extra-content {
      padding: 0;
    }
    .product-extra-content__inner-wrapper {
      position: relative;
      min-height: 300px;
      padding-bottom: 5px;
    }
    .extra-content-grid__container {
      padding-bottom: 5px;
    }
  }
  &__meta-heada {
    @include pb-right;
    @include breakpoint($small-down) {
      margin-bottom: 5px;
    }
  }
  &__tagline {
    font-size: 16px;
    text-align: center;
  }
  &__flag-container {
    @include pb-right;
    @include breakpoint($medium-up) {
      position: absolute;
      top: 26px;
      width: 100%;
      padding-right: 40px;
      .product-extra-content & {
        padding-right: 0;
        position: static;
        margin: 0 0 20px 0;
      }
    }
  }
  &__flag {
    margin: 0 0 10px 1px;
    @include breakpoint($medium-up) {
      margin: 0 auto;
    }
    &--recommended {
      display: none;
      .product-brief--recommended & {
        display: inline-block;
        .mpp--regimen-view & {
          display: none;
        }
      }
    }
    &-container {
      @include breakpoint($medium-down) {
        display: none;
      }
    }
  }
  &__concern {
    @include font--text-alt;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: $color-darker-gray;
    letter-spacing: 0;
    //margin: 0 0 15px;
    @include breakpoint($medium-up) {
      max-width: 248px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 1.3em;
    }
  }
  &__img {
    @include pb-left;
    display: block;
    height: auto;
    margin: -20px 0 0;
    &__grid-image {
      img {
        width: 100%;
      }
    }
    &__main-image {
      display: none;
    }
    &__main-image-small {
      display: block;
    }
    @include breakpoint($medium-up) {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      &__grid-image {
        display: inline-block;
        max-width: 32.9%;
      }
      &__grid-image:nth-of-type(1) {
        float: left;
      }
      &__grid-image:nth-of-type(3) {
        float: right;
      }
      &__main-image {
        display: block;
      }
      &__main-image-small {
        display: none;
      }
    }
  }
  &__title {
    @include pb-right;
    @include font--heading;
    font-size: 11px;
    margin: 0 0 2px;
    font-weight: normal;
    @include breakpoint($medium-up) {
      font-size: 13px;
      line-height: 1.3;
      margin: 0 0 5px;
    }
    &__link {
      @if $cr18 == true {
        color: $color-cr18-black;
      }
      @if $cr18 == false {
        color: $color-green;
      }
      .product-extra-content & {
        color: $color-black;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        font-family: $font--futura-demi;
        @include breakpoint($medium-up) {
          font-size: 20px;
        }
        @include breakpoint($medium-only) {
          font-size: 18px;
        }
        @include breakpoint($medium-down) {
          letter-spacing: -1px;
        }
      }
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .product-extra-content & {
      margin: 0;
      line-height: 1.3;
    }
  }
  &__subtitle {
    @include pb-right;
    @include font--subheading;
    font-size: 22px;
    margin: 0;
    @include breakpoint($medium-up) {
      font-size: 24px;
    }
    &__link {
      color: $color-black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    .product-extra-content & {
      @include font--heading;
      font-size: 15px;
      font-family: $font--futura-demi;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
      @include breakpoint($medium-only) {
        font-size: 18px;
      }
      @include breakpoint($medium-down) {
        letter-spacing: -1px;
      }
      line-height: 1.3;
    }
  }
  &__desc {
    @include pb-right;
  }
  &__subline {
    @include pb-right;
    @include font--heading;
    margin: 10px 0;
  }
  &__rating {
    @include pb-right;
    line-height: 1;
    margin: 0 0 4px;
    @include breakpoint($medium-up) {
      margin: 0;
      line-height: 1.45;
    }
    // new styles for star ratings
    &__no-stars {
      display: inline-block;
      position: relative;
      @include font--cta;
      font-size: 12px;
    }
    // off stars - grey
    &__stars {
      display: inline-block;
      position: relative;
      width: 93px;
      height: 15px;
      // @todo remove base64
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF0AAAAPCAMAAACx6TMNAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAByFBMVEUAAAC6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6urq6uroAAABmqQeUAAAAlnRSTlMAMjMGzwUB0AQDAklINEU2QzhCOp2Fm4eZipeLlI7r2era6Nzn3ubfEU03TDk7PT8I/vyDkv2EB5CGj42JTuzj5EDlSulEG76wExq9sRm8shQYu7MVurW5pLelpqmr4svN3dHTziAhIzElLybuUmP1cu9TYvR0VV/zdVdeeIHwWF3yesIeKMbDJ8fFH8nWzNUtgCspjBzzsciJAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAeNJREFUOMuN0/lfEkEUAPBJTsEDTSLFQBQhtYJCwezUPCtSs7RbKzoIL+yyg9IKkjwqy/f3Ojs7187+4vt8Znb2+3mft/tmZxGicQiZ4mBUcQCyWE05NqvdRA4TOc1ZlQ6XIm4wvVYVVKtUA7UqeaBOpXo4rEgDeNWkI+BT6Sg0qtQEfpWa4ZjSXwCCan8tEFKpFdoUcoWhXaUIRNnaclyLDgDoJCsnoy5MJ4zkxnSSrGz4G5BFFaZTRqrBFKMUbz4NIs4kcKl4t0w9SUypXgOd1ajPQD5cPXXOQOcxoQsXOVyK6Q11BDhd7tepq4XTQL1OVwRFPPRwDHIKD+k0PEJhdIzt19VrlK5zSt+gND7BaPImpalbnG5TmubkmiFwR/40dwndkw6za5rQfZmmCD2QyD5O6KEg+yMis1J1e5TQnHwY2gkZfrvHhJ7IFCL0VEAtvs3g8UxQNaXngiq00ni8EPSSZmUFvaKU49II8wtocQmWRZIP8ivo9Rt4K8gL+Xfo/Sp8EJSE/Ef0aRU+CyrAl69obR2+cWn7/gPPRWtJ6u+nRhuZsqDgr0085+a2BG2XtHazO9Ke/i5r7WZndxj8+VtJrqm+NKPdgpPSMKN/CUr/9xgVkzpZ/JuMcl6dbH7tufvdFd3esky0MQAAAABJRU5ErkJggg==');
    }
    // on stars - green
    &__stars-rating {
      position: absolute;
      height: 15px;
      width: 100%;
      overflow: hidden;
      // @todo remove base64
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF0AAAAPCAMAAACx6TMNAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAApVBMVEUAAAB+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJF+yJEAAAAUCViUAAAANXRSTlMAMzYwA8/SS0g5Pzych5aKkO3b6uHkLQb8gYRCG7qxEhi0FaIeJPBUYPN4Wl3ewyfGIczJfrTLyGcAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAA30lEQVQ4y7XU2RKCMAwF0IiACCKoIIqKG+47mv//NQERO20BGTEzfch5Sm47BUiqBkx9RwJLdRpECaojWaSkgcwMHFJ41KRJRY2SFupQTG0eGSyZ1DId7EIx9Xhk0WTTpCBiv5A0PgksvdNyTCRqMBT5ZBQQunyCRi/tbTW5UJaU0jR60XiSgDd9b8ehWTmaL9Ko5jEsyfR+JI8QKxYZ/kJC2Erh8XOpnk0rlj5vS8f1BrY73EMVtDvAkaTTOZrnIp0hj+TrLYOCgKB7RKsgjab/eP06junnkZtNBkt6RE92FEuFL+eXlQAAAABJRU5ErkJggg==');
    }
    &__count {
      @include font--cta;
      font-size: 12px;
      position: relative;
      top: -2px;
      margin: 0 0 0 3px;
      &:before {
        @include font--subheading;
        // font-family: $font--subheading;
        font-size: 11px;
        content: '\2022';
        margin-right: 7px;
      }
    }
  }
  &__price-points {
    @include pb-right;
    margin: 0 0 21px;
    @include breakpoint($medium-up) {
      margin: 0;
    }
  }
  &__price {
    @include font--text-alt;
    font-size: 24px;
    @if $cr18 == true {
      font-size: 20px;
    }
    letter-spacing: -0.01em;
    line-height: 1;
    margin: 0 5px 0 0;
    display: inline-block;
    @include breakpoint($medium-up) {
      // changed in ORNF-178 to 24px, like mobile
      // font-size: 34px;
      display: block;
      margin: 0 0 13px;
    }
    .product-extra-content & {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 18px;
      display: block;
      text-align: center;
      @include breakpoint($medium-up) {
        margin-top: 18px;
        margin-bottom: 0;
      }
    }
  }
  &__points {
    @include font--cta;
    font-size: 14px;
    color: #a6a6a6;
    letter-spacing: -0.01em;
    line-height: 1;
    display: inline-block;
    margin: 0;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__footer {
    @include pb-right;
    @include breakpoint($medium-up) {
      display: block;
      padding: 28px 0 0;
    }
    .product-extra-content & {
      @include breakpoint($medium-up) {
        display: block;
        padding: 18px 0 0;
      }
    }
  }
  &__cta {
    width: 100%;
    &--shop-now {
      max-width: 248px;
      margin: 0;
      @include breakpoint($medium-up) {
        margin: 0 0 17px;
      }
    }
    &--quickshop {
      // No quickshop on mobile (?)
      display: none;
      @include breakpoint($medium-up) {
        display: inline-block;
      }
    }
  }
  .button--medium {
    width: 100%;
    min-width: 0;
  }
  &__grid-text__container {
    padding: 15px 20px 25px 20px;
    font-size: 16px;
    line-height: 1.4;
    display: inline-block;
    text-align: center;
    width: 100%;
    &:nth-of-type(1) {
      float: left;
    }
    &:nth-of-type(3) {
      float: right;
    }
    @include breakpoint($medium-up) {
      display: inline-block;
      line-height: 1.5;
    }
  }
  &__grid-text__sub-header {
    @include font--heading;
    color: $color-black;
    font-size: 16px;
    font-family: $font--futura-medium;
    line-height: 0.7;
    .product-extra-content & {
      font-family: $font--futura-demi;
    }
    @include breakpoint($medium-up) {
      font-size: 14px;
      line-height: 1;
    }
    @include breakpoint(($medium-only) (orientation portrait)) {
      font-size: 13px;
    }
  }
  &__grid-text__header {
    @include font--heading--tertiary;
    color: $color-black;
    font-size: 30px;
    padding: 6px 0 7px 0;
    @include breakpoint($medium-up) {
      font-size: 31px;
    }
    @include breakpoint(($medium-only) (orientation portrait)) {
      min-height: 80px;
      font-size: 29px;
    }
  }
  &__container {
    .product-grid-extra-content & {
      padding: 0 0 30px 0;
      @include breakpoint($medium-up) {
        padding: initial;
      }
    }
    &-wrapper {
      padding: 15px 0 30px 0;
      @include breakpoint($medium-up) {
        padding: initial;
      }
    }
  }
}

// testing cta changes for mobile
.product-grid .product-brief__ctas--mobile {
  .product-brief__cta--select-sku,
  .product-add-to-waitlist,
  .product-add-to-bag {
    line-height: 20px;
    padding: 5px 20px;
    font-size: 20px;
  }
}

.product-grid.product-grid-extra-content .product-brief__ctas--mobile {
  .product-brief__cta--select-sku,
  .product-add-to-waitlist,
  .product-add-to-bag {
    font-size: 17px;
    padding: 6px 20px;
    width: auto;
    @include breakpoint($medium-up) {
      padding: 8px 30px;
    }
    &.button--disabled {
      display: none;
    }
  }
}
